<template>
  <div>
    <div v-if="loading">
      <div class="d-flex justify-content-center mx-5 mt-3 mb-5">
        <div class="spinner-border text-success align-self-center loader-lg" />
      </div>
    </div>
    <div v-else-if="loadingError" class="cm-loading-error">
      {{ $t("calendarModule.errors.loadingError") }}
    </div>
    <div v-else>
      <b-row>
        <b-col>
          <b-form-group
            :description="
              $t('calendarModule.defaultSlotForm.descriptions.type')
            "
          >
            <template v-slot:label>
              {{ $t("calendarModule.defaultSlotForm.labels.type") }}
              <span class="text-danger">*</span>
            </template>

            <b-form-select
              :options="typeOptions"
              v-model="chosenType"
              :disabled="typeOptions.length === 1"
            />
          </b-form-group>
        </b-col>
      </b-row>
      <b-row v-if="!isMobileScreenSize && !isTabletScreenSize">
        <b-col :cols="3">
          <b-form-group
            :description="
              $t('calendarModule.defaultSlotForm.descriptions.weekday')
            "
          >
            <template v-slot:label>
              {{ $t("calendarModule.defaultSlotForm.labels.weekday") }}
              <span class="text-danger">*</span>
            </template>
            <!-- <b-input :value="forDateFormattedWeekday" :disabled="true" />-->
            <v-select
              :options="forDateFormattedWeekdayOptions"
              v-model="chosenWeekday"
              @input="resetChosenUniqueSlots"
            />
          </b-form-group>
        </b-col>
        <b-col :cols="3">
          <b-form-group
            :description="
              $t('calendarModule.defaultSlotForm.descriptions.time')
            "
          >
            <template v-slot:label>
              {{ $t("calendarModule.defaultSlotForm.labels.time") }}
            </template>
            <b-form-timepicker
              v-model="defaultSlot.time"
              :minutes-step="5"
              :hour12="false"
              @input="refreshChosenUniqueSlots"
            />
          </b-form-group>
        </b-col>
        <b-col>
          <b-form-group
            :description="
              $t('calendarModule.slotForm.descriptions.slotLengthCounter')
            "
          >
            <template v-slot:label>
              {{ $t("calendarModule.slotForm.labels.slotLengthCounter") }}
              <span class="text-danger">*</span>
            </template>
            <div class="mt-2">
              <img
                class="feathericons-minus-circle p-3 mr-2 mb-2 bg-danger btn "
                @click="subtractSlotCounter()"
              />
              <span class="font-size-middle">{{ slotCount }}</span>
              <img
                class="feathericons-plus-circle p-3 ml-2 mb-2 bg-success btn"
                @click="addSlotCounter()"
              />
            </div>
          </b-form-group>
        </b-col>
        <b-col :cols="3">
          <b-form-group
            :description="
              $t('calendarModule.defaultSlotForm.descriptions.endTime')
            "
          >
            <template v-slot:label>
              {{ $t("calendarModule.defaultSlotForm.labels.endTime") }}
            </template>
            <b-form-timepicker
              v-model="endTimeFormated"
              :minutes-step="5"
              :hour12="false"
              :disabled="true"
            />
          </b-form-group>
        </b-col>
        <b-col class="col-12">
          <div v-if="chosenSlotsWithUniqueSlots.length > 0">
            <strong>{{
              $t("calendarModule.slotForm.massCreation.availableSlots")
            }}</strong>

            <b-badge
              v-for="uniqueSlot of chosenSlotsWithUniqueSlots"
              :key="'slotExampleTime' + uniqueSlot.time"
              class="cm-badge-list-element mb-1"
              variant="light"
            >
              {{ uniqueSlot.time }} - {{ uniqueSlot.endTime }}
            </b-badge>
          </div>
        </b-col>
        <b-col class="col-12 mb-3">
          <div
            v-if="chosenSlotsWithUniqueSlots.length > 0 && chosenType === 'gom'"
          >
            <strong
              ><b-img class="feathericons-alert-circle bg-dark p-2 pb-3" />
              {{
                $t(
                  "calendarModule.defaultSlotForm.massCreation.followupWarning",
                  { weekday: getUpcomingWeekday(chosenWeekday) }
                )
              }}</strong
            >
          </div>
        </b-col>
      </b-row>
      <b-row v-else>
        <b-col>
          <b-form-group
            :description="
              $t('calendarModule.defaultSlotForm.descriptions.weekday')
            "
          >
            <template v-slot:label>
              {{ $t("calendarModule.defaultSlotForm.labels.weekday") }}
              <span class="text-danger">*</span>
            </template>
            <v-select
              :options="forDateFormattedWeekdayOptions"
              v-model="chosenWeekday"
              @input="resetChosenUniqueSlots"
            />
          </b-form-group>
          <b-form-group
            :description="
              $t('calendarModule.defaultSlotForm.descriptions.time')
            "
          >
            <template v-slot:label>
              {{ $t("calendarModule.defaultSlotForm.labels.time") }}
            </template>
            <b-form-timepicker
              v-model="defaultSlot.time"
              :minutes-step="5"
              :hour12="false"
              @input="refreshChosenUniqueSlots"
            />
          </b-form-group>
          <b-form-group
            :description="
              $t('calendarModule.defaultSlotForm.descriptions.endTime')
            "
          >
            <template v-slot:label>
              {{ $t("calendarModule.defaultSlotForm.labels.endTime") }}
            </template>
            <b-form-timepicker
              v-model="endTimeFormated"
              :minutes-step="5"
              :hour12="false"
              :disabled="true"
            />
          </b-form-group>
          <b-form-group
            :description="
              $t('calendarModule.slotForm.descriptions.slotLengthCounter')
            "
          >
            <template v-slot:label>
              {{ $t("calendarModule.slotForm.labels.slotLengthCounter") }}
              <span class="text-danger">*</span>
            </template>
            <div class="mt-2">
              <img
                class="feathericons-minus-circle p-3 mr-2 mb-2 bg-danger btn "
                @click="subtractSlotCounter()"
              />
              <span class="font-size-middle">{{ slotCount }}</span>
              <img
                class="feathericons-plus-circle p-3 ml-2 mb-2 bg-success btn"
                @click="addSlotCounter()"
              />
            </div>
          </b-form-group>
        </b-col>
        <b-col class="col-12">
          <div v-if="chosenSlotsWithUniqueSlots.length > 0">
            <strong>{{
              $t("calendarModule.slotForm.massCreation.availableSlots")
            }}</strong>

            <b-badge
              v-for="uniqueSlot of chosenSlotsWithUniqueSlots"
              :key="'slotExampleTime' + uniqueSlot.time"
              class="cm-badge-list-element mb-1"
              variant="light"
            >
              {{ uniqueSlot.time }} - {{ uniqueSlot.endTime }} A
            </b-badge>
          </div>
        </b-col>
        <b-col class="col-12 mb-3">
          <div
            v-if="chosenSlotsWithUniqueSlots.length > 0 && chosenType === 'gom'"
          >
            <strong
              ><b-img class="feathericons-alert-circle bg-dark p-2 pb-3"
            /></strong>
          </div>
        </b-col>
      </b-row>
      <b-row>
        <b-col>
          <b-form-group
            :description="
              $t('calendarModule.defaultSlotForm.descriptions.fromDate')
            "
            :state="!$v.fromDate.$error"
          >
            <template v-slot:label>
              {{ $t("calendarModule.defaultSlotForm.labels.fromDate") }}
              <span class="text-danger">*</span>
            </template>
            <date-picker
              class="w-100"
              format="DD.MM.YYYY"
              input-class="form-control pac-target-input"
              v-model="$v.fromDate.$model"
              @change="fromDateChanged"
            ></date-picker>
            <b-form-invalid-feedback>{{
              $t("calendarModule.defaultSlotForm.errors.fromDate")
            }}</b-form-invalid-feedback>
          </b-form-group>
        </b-col>
        <b-col>
          <b-form-group
            :description="
              $t('calendarModule.defaultSlotForm.descriptions.toDate')
            "
            :state="!$v.toDate.$error"
          >
            <template v-slot:label>
              {{ $t("calendarModule.defaultSlotForm.labels.toDate") }}
              <span class="text-danger">*</span>
            </template>
            <date-picker
              class="w-100"
              format="DD.MM.YYYY"
              input-class="form-control pac-target-input"
              v-model="$v.toDate.$model"
              @change="toDateChanged"
            ></date-picker>
            <b-form-invalid-feedback>{{
              $t("calendarModule.defaultSlotForm.errors.toDate")
            }}</b-form-invalid-feedback>
          </b-form-group>
        </b-col>
      </b-row>
      <b-row class="mt-4">
        <b-col>
          <b-button
            class="btn btn-primary"
            size="xs"
            @click="saveNewBookings()"
          >
            {{ $t("misc.save") }}
          </b-button>
          <b-button
            class="btn btn-outline-primary ml-2"
            size="xs"
            @click="slotsChanged"
          >
            {{ $t("misc.abort") }}
          </b-button>
        </b-col>
      </b-row>

      <div v-if="withSlotActions && defaultSlot._id" class="mt-3">
        <div class="cm-hr-line" />
        <div class="mt-3">
          <MassDefaultSlotsActions
            :default-slots="[defaultSlot]"
            @slotsChanged="slotsChanged"
            :no-list="true"
          />
        </div>
      </div>

      <b-modal
        :title="$t('calendarModule.modalTitles.excludedDate')"
        ref="excludedDateModal"
        hide-footer
      >
        <b-form-group>
          <template v-slot:label>
            {{ $t("calendarModule.defaultSlotForm.labels.date") }}
            <span class="text-danger">*</span>
          </template>
          <date-picker
            class="w-100"
            format="DD.MM.YYYY"
            input-class="form-control pac-target-input"
            v-model="newExcludedDate"
            @change="newExcludedDateChanged"
          ></date-picker>
        </b-form-group>
        <b-button class="btn btn-primary" size="xs" @click="addExcludedDate">
          {{ $t("misc.add") }}
        </b-button>
      </b-modal>
    </div>
  </div>
</template>

<script>
import * as cmApi from "../../../store/modules/calendarModule/calendarModule.api";
import * as moment from "moment";
import vSelect from "vue-select";
import "vue-select/dist/vue-select.css";
import { validationMixin } from "vuelidate";
import { v4 as uuidv4 } from "uuid";
import { addNotification } from "../../../utils/notificationHandler";
import DatePicker from "vue2-datepicker";
import "vue2-datepicker/locale/de";
import "vue2-datepicker/index.css";
import {
  required,
  minLength,
  maxLength,
  integer,
  minValue,
  maxValue,
  requiredIf
} from "vuelidate/lib/validators";
import { mapGetters } from "vuex";
import * as userApi from "../../../store/modules/users/users.api";
import { convertToCalendarTime } from "../../../utils/timezoneHelper";
import screenSizeMixin from "@/mixins/screenSize";
import { EConversationTypes } from "@/store/modules/conversations/EConversationTypes";
import { EUserRoles } from "@/store/modules/users/EUserRoles";
import config from "@/config/config";

export default {
  name: "DefaultSlotForm",
  props: [
    "theCalendar",
    "theDefaultSlot",
    "forDate",
    "withSlotActions",
    "withSlotType"
  ],
  components: {
    MassDefaultSlotsActions: () =>
      import(
        "../../../components/calendarModule/common/MassDefaultSlotsActions"
      ),
    vSelect,
    DatePicker
  },
  mixins: [validationMixin, screenSizeMixin],
  validations: {
    fromDate: {
      required
    },
    toDate: {
      required
    },
    defaultSlot: {
      daysBefore: {
        required: requiredIf(function() {
          return this.defaultSlot.daysBefore !== null;
        }),
        integer,
        minValue: minValue(0),
        maxValue: maxValue(90)
      }
    },
    massCreation: {
      amountPerTimeFrame: {
        integer,
        required: requiredIf(function() {
          return this.massCreation;
        }),
        minValue: minValue(1),
        maxValue: maxValue(50)
      },
      repeatMinuteGap: {
        integer,
        required: requiredIf(function() {
          return this.massCreation;
        }),
        minValue: minValue(0),
        maxValue: maxValue(120)
      }
    }
  },
  data() {
    return {
      slotCount: 0,
      slotLength: 90,
      moment: moment,
      isBookingConflict: false,
      isFailedBookingsConfirmed: false,
      failedBookings: [],
      chosenSlots: [],
      chosenSlotsWithUniqueSlots: [],
      chosenType: EConversationTypes.ONE_ON_ONE,
      newBookingArray: [],
      chosenWeekday: {
        label: this.formatedDayLabel(),
        code: moment().format("YYYY-MM-DD")
      },
      currentUser: null,
      fromWeekDay: null,
      toWeekday: null,
      cmApi: cmApi,
      loading: false,
      loadingError: false,
      calendar: null,
      defaultSlot: null,
      baseDefaultSlot: {
        time: "12:00",
        endTime: "12:15",
        date: this.forDate || moment().format("YYYY-MM-DD"),
        endDate: this.forDate || moment().format("YYYY-MM-DD"),
        excludedDates: [],
        daysBefore: null,
        from: moment().format("YYYY-MM-DD"),
        to: moment()
          .add(1, "year")
          .format("YYYY-MM-DD"),
        metadata: {}
      },
      massCreation: null,
      slotsToSave: [],
      newExcludedDate: null,
      fromDate: moment().toDate(),
      toDate: moment()
        .add(1, "year")
        .toDate()
    };
  },
  computed: {
    ...mapGetters("users", ["getCurrentUser"]),
    typeOptions() {
      return Object.values(EConversationTypes)
        .filter(type => {
          return (
            type !== EConversationTypes.GOM ||
            this.getCurrentUser.role === EUserRoles.STUDENT
          );
        })
        .map(type => {
          return {
            text: this.$t(`calendarModule.defaultSlotForm.typeOptions.${type}`),
            value: type
          };
        });
    },
    days() {
      const days = [];
      if (this.fromWeekDay && this.toWeekday) {
        const end = this.toWeekday.clone().endOf("day");
        const iDate = this.fromWeekDay.clone();
        while (iDate.isBefore(end)) {
          days.push(iDate.clone());
          iDate.add(1, "day");
        }
      }
      return days;
    },
    endTimeFormated() {
      return moment(this.defaultSlot.time, "HH:mm")
        .add(
          this.chosenType === EConversationTypes.GOM
            ? config.gomDuration
            : this.slotLength,
          "minutes"
        )
        .format("HH:mm");
    },
    forDateFormattedWeekdayOptions() {
      const options = [];
      this.days.forEach(day => {
        let label = "";
        switch (moment(day).format("dd")) {
          case "Mo":
            label = "Montag";
            break;
          case "Tu":
          case "Di":
            label = "Dienstag";
            break;
          case "We":
          case "Mi":
            label = "Mittwoch";
            break;
          case "Th":
          case "Do":
            label = "Donnerstag";
            break;
          case "Fr":
            label = "Freitag";
            break;
          case "Sa":
            label = "Samstag";
            break;
          case "Su":
          case "So":
            label = "Sonntag";
            break;
        }
        options.push({
          label: label,
          code: moment(day).format("YYYY-MM-DD")
        });
      });
      return options;
    },
    forDateFormatted() {
      return moment(this.defaultSlot.date).format("DD.MM.YYYY");
    },
    forDateFormattedWeekday() {
      return moment(this.defaultSlot.date).format("dd");
    },
    massCreationSlots() {
      //  if (!this.inputsValid) {
      //   return null;
      //  }
      if (this.massCreation) {
        const massUuid = uuidv4();
        const allSlots = [];
        const uniqueSlots = [];
        const baseTime = moment(this.defaultSlot.time, "HH:mm");
        //  const baseEndTime = moment(this.defaultSlot.endTime, "HH:mm");
        const baseEndTime = moment(this.defaultSlot.time, "HH:mm").add(
            this.chosenType === EConversationTypes.GOM
                ? config.gomDuration
                : this.slotLength,
            "minutes"
        );
        const untilTime = moment(this.massCreation.repeatUntil, "HH:mm");
        const slotLength =
          this.chosenType === EConversationTypes.GOM
            ? config.gomDuration
            : this
                .slotLength; /*Math.round(
          moment.duration(baseEndTime.diff(baseTime)).asMinutes()
        );*/
        const diffMinutes =
          parseInt(slotLength, 10) +
          parseInt(this.massCreation.repeatMinuteGap, 10);
        while (baseEndTime.isSameOrBefore(untilTime)) {
          for (let i = 0; i < this.massCreation.amountPerTimeFrame; i++) {
            allSlots.push({
              ...this.defaultSlot,
              metadata: {
                ...this.defaultSlot.metadata,
                massUuid
              },
              time: baseTime.format("HH:mm"),
              endTime: baseEndTime.format("HH:mm")
            });
          }
          uniqueSlots.push({
            ...this.defaultSlot,
            metadata: {
              ...this.defaultSlot.metadata,
              massUuid
            },
            time: baseTime.format("HH:mm"),
            endTime: baseEndTime.format("HH:mm")
          });
          baseTime.add(diffMinutes, "minutes");
          baseEndTime.add(diffMinutes, "minutes");
        }
        return { allSlots, uniqueSlots };
      }
      return null;
    },
    inputsValid() {
      this.$v.$touch();
      if (this.$v.$anyError) {
        return false;
      }
      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      this.defaultSlot.time = `${this.defaultSlot.time.split(":")[0]}:${
        this.defaultSlot.time.split(":")[1]
      }`;
      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      this.defaultSlot.endTime = `${this.defaultSlot.endTime.split(":")[0]}:${
        this.defaultSlot.endTime.split(":")[1]
      }`;
      if (this.defaultSlot.time >= this.defaultSlot.endTime) {
        return false;
      }
      if (this.massCreation) {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.massCreation.repeatUntil = `${
          this.massCreation.repeatUntil.split(":")[0]
        }:${this.massCreation.repeatUntil.split(":")[1]}`;
        if (this.massCreation.repeatUntil < this.defaultSlot.endTime) {
          return false;
        }
      }
      return true;
    }
  },
  methods: {
    getUpcomingWeekday(currentWeekday) {
      const futureDate = moment(currentWeekday.code).add(3, "days");
      return this.formatedDayLabel(futureDate);
    },
    resetChosenUniqueSlots() {
      this.chosenSlotsWithUniqueSlots = [];
      this.slotCount = 0;
    },
    saveNewBookings() {
      if (this.chosenSlotsWithUniqueSlots.length > 0) {
        for (let i = 0; i < this.chosenSlotsWithUniqueSlots.length; i++) {
          const convertedUtc = convertToCalendarTime(
            this.chosenWeekday.code,
            this.chosenWeekday.code,
            this.chosenSlotsWithUniqueSlots[i].time,
            this.chosenSlotsWithUniqueSlots[i].endTime
          );
          const payload = {
            time: convertedUtc.time,
            endTime: convertedUtc.endTime,
            // date: this.defaultSlot.date,
            date: convertedUtc.date,
            endDate: convertedUtc.endDate,
            excludedDates: this.defaultSlot.excludedDates,
            from: this.defaultSlot.from,
            to: this.defaultSlot.to,
            metadataForSlot: {
              type: this.chosenType
            }
          };
          cmApi.defaultSlot
            .createDefaultTimeSlot(this.currentUser._id, payload)
            .then(res => {
              this.$emit("slotsAddedOrSaved");
            });
        }
      }
    },
    refreshChosenUniqueSlots() {
      this.toggleMassCreation();
      const massCreationSlots = this.massCreationSlots;
      this.chosenSlotsWithUniqueSlots = JSON.parse(
        JSON.stringify(massCreationSlots.uniqueSlots)
      );
    },
    addSlotCounter() {
      this.slotCount = this.slotCount + 1;
      this.toggleMassCreation();
      const massCreationSlots = this.massCreationSlots;

      this.chosenSlotsWithUniqueSlots = JSON.parse(
        JSON.stringify(massCreationSlots.uniqueSlots)
      );
    },
    subtractSlotCounter() {
      if (this.slotCount > 0) {
        this.slotCount = this.slotCount - 1;
        this.toggleMassCreation();
        const massCreationSlots = this.massCreationSlots;
        if (massCreationSlots === null) {
          this.chosenSlotsWithUniqueSlots = [];
        } else {
          this.chosenSlotsWithUniqueSlots = JSON.parse(
            JSON.stringify(massCreationSlots.uniqueSlots)
          );
        }
      }
    },
    checkForFailedBookings() {
      this.failedBookings = [];
      this.newBookingArray = JSON.parse(
        JSON.stringify(this.chosenSlotsWithUniqueSlots)
      );
      this.isBookingConflict = false;
      // let slots = null;
      const payload = {
        time: this.defaultSlot.time,
        endTime: this.defaultSlot.endTime,
        //  date: this.defaultSlot.date,
        date: this.chosenWeekday.code,
        endDate: this.defaultSlot.endDate,
        excludedDates: this.defaultSlot.excludedDates,
        from: this.defaultSlot.from,
        to: this.defaultSlot.to
      };
    },
    formatedDayLabel(date = moment()) {
      let label = date.format("dd");
      switch (label) {
        case "Mo":
          label = "Montag";
          break;
        case "Tu":
        case "Di":
          label = "Dienstag";
          break;
        case "We":
        case "Mi":
          label = "Mittwoch";
          break;
        case "Th":
        case "Do":
          label = "Donnerstag";
          break;
        case "Fr":
          label = "Freitag";
          break;
        case "Sa":
          label = "Samstag";
          break;
        case "Su":
        case "So":
          label = "Sonntag";
          break;
      }
      return label;
    },
    getFormattedDate(dateStr) {
      return moment(dateStr).format("DD.MM.YYYY");
    },
    removeExcludedDate(dateStr) {
      this.defaultSlot.excludedDates = this.defaultSlot.excludedDates.filter(
        exDate => exDate !== dateStr
      );
    },
    newExcludedDateChanged() {
      if (this.newExcludedDate) {
        const baseMoment = moment(this.newExcludedDate);
        baseMoment.add(13, "hours");
        this.newExcludedDate = baseMoment.toDate();
      }
    },
    addExcludedDate() {
      if (this.newExcludedDate) {
        const todayStr = moment().format("YYYY-MM-DD");
        const selectedStr = moment(this.newExcludedDate).format("YYYY-MM-DD");
        this.defaultSlot.excludedDates = this.defaultSlot.excludedDates.filter(
          exDate => exDate !== selectedStr
        );
        this.defaultSlot.excludedDates.push(selectedStr);
        this.defaultSlot.excludedDates = this.defaultSlot.excludedDates.filter(
          exDate => exDate >= todayStr
        );
        this.$refs["excludedDateModal"].hide();
      } else {
        addNotification(
          "error",
          this.$t("misc.error"),
          this.$t("calendarModule.calendarForm.errors.missingInput")
        );
      }
    },
    toggleMassCreation() {
      this.massCreation = {
        amountPerTimeFrame: 1,
        repeatUntil: this.endTimeFormated, //this.defaultSlot.endTime,
        repeatMinuteGap: 0
      };
    },
    loadCalendar() {
      if (this.theCalendar && typeof this.theCalendar !== "string") {
        this.calendar = this.theCalendar;
      } else {
        this.calendar = null;
        // this.loading = true;

        this.calendar = this.theCalendar;
      }
    },
    save() {
      this.$v.$touch();
      if (this.$v.$anyError) {
        addNotification(
          "error",
          this.$t("misc.error"),
          this.$t("calendarModule.errors.inputInvalid")
        );
        return false;
      }
      this.defaultSlot.time = `${this.defaultSlot.time.split(":")[0]}:${
        this.defaultSlot.time.split(":")[1]
      }`;
      this.defaultSlot.endTime = `${this.defaultSlot.endTime.split(":")[0]}:${
        this.defaultSlot.endTime.split(":")[1]
      }`;
      if (this.defaultSlot.time >= this.defaultSlot.endTime) {
        addNotification(
          "error",
          this.$t("misc.error"),
          this.$t("calendarModule.defaultSlotFrom.errors.endBeforeStart")
        );
        return false;
      }
      if (this.massCreation) {
        this.massCreation.repeatUntil = `${
          this.massCreation.repeatUntil.split(":")[0]
        }:${this.massCreation.repeatUntil.split(":")[1]}`;
        if (this.massCreation.repeatUntil < this.defaultSlot.endTime) {
          addNotification(
            "error",
            this.$t("misc.error"),
            this.$t("calendarModule.defaultSlotFrom.errors.repeatUntil")
          );
          return false;
        }
      }
      if (this.massCreationSlots) {
        this.slotsToSave = this.massCreationSlots.allSlots;
      } else {
        this.slotsToSave = [this.defaultSlot];
      }

      this.loading = true;
      this.saveNextSlot();
    },
    saveNextSlot() {
      const slot = this.slotsToSave.shift();
      if (!slot) {
        this.loading = false;
        addNotification(
          "success",
          this.$t("misc.success"),
          this.$t("calendarModule.messages.slotsSaved")
        );
        this.$emit("slotsAddedOrSaved");
        return;
      }

      if (slot._id) {
        const _id = slot._id;
        delete slot._id;

        const payload = {
          time: this.defaultSlot.time,
          endTime: this.defaultSlot.endTime,
          //  date: this.defaultSlot.date,
          date: this.chosenWeekday.code,
          endDate: this.defaultSlot.endDate,
          excludedDates: this.defaultSlot.excludedDates,
          from: this.defaultSlot.from,
          to: this.defaultSlot.to
        };
        this.loadingError = false;
        this.loading = true;
        cmApi.defaultSlot
          .updateDefaultTimeSlot(this.currentUser._id, payload)
          .then(res => {
            this.loading = false;
            this.loadingError = false;
            this.saveNextSlot();
          })
          .catch(err => {
            this.loading = false;
            this.loadingError = true;
          });
      } else {
        const payload = {
          time: this.defaultSlot.time,
          endTime: this.defaultSlot.endTime,
          // date: this.defaultSlot.date,
          date: this.chosenWeekday.code,
          endDate: this.defaultSlot.endDate,
          excludedDates: this.defaultSlot.excludedDates,
          from: this.defaultSlot.from,
          to: this.defaultSlot.to
        };
        this.loadingError = false;
        this.loading = true;
        cmApi.defaultSlot
          .createDefaultTimeSlot(this.currentUser._id, payload)
          .then(res => {
            const defaultTimeSlot = res.data.data;
            this.loading = false;
            this.loadingError = false;
            this.saveNextSlot();
            this.$emit("slotCreated", defaultTimeSlot);
          })
          .catch(err => {
            this.loading = false;
            this.loadingError = true;
          });
      }
    },
    slotsChanged() {
      this.$emit("slotsAddedOrSaved");
    },
    fromDateChanged() {
      if (this.fromDate) {
        const baseMoment = moment(this.fromDate);
        baseMoment.add(13, "hours");
        this.fromDate = baseMoment.toDate();
        this.defaultSlot.from = baseMoment.format("YYYY-MM-DD");
      }
    },
    toDateChanged() {
      if (this.toDate) {
        const baseMoment = moment(this.toDate);
        baseMoment.add(13, "hours");
        this.toDate = baseMoment.toDate();
        this.defaultSlot.to = baseMoment.format("YYYY-MM-DD");
      }
    }
  },
  created() {
    if (this.getCurrentUser) {
      this.currentUser = this.getCurrentUser;
    }
    if (typeof this.theDefaultSlot === "string") {
      this.defaultSlot = null;

      this.loading = true;
      this.loadingError = false;
      cmApi.defaultSlot
        .getAllDefaultTimeSlots(this.currentUser._id)
        .catch(res => {
          const defaultTimeSlot = res.data.data;
          this.defaultSlot = defaultTimeSlot;

          if (this.defaultSlot.fromDate) {
            this.fromDate = moment(this.defaultSlot.fromDate).toDate();
          }
          if (this.defaultSlot.toDate) {
            this.toDate = moment(this.defaultSlot.toDate).toDate();
          }
          this.loading = false;
          this.loadingError = false;
          this.loadCalendar();
        })
        .catch(err => {
          this.loading = false;
          this.loadingError = false;
        });
    } else {
      this.defaultSlot = this.theDefaultSlot || this.baseDefaultSlot;
      if (this.defaultSlot.from) {
        this.fromDate = moment(this.defaultSlot.from).toDate();
      }
      if (this.defaultSlot.to) {
        this.toDate = moment(this.defaultSlot.to).toDate();
      }

      const baseDate = moment();
      baseDate.startOf("isoWeek");
      this.fromWeekDay = baseDate.clone();
      this.toWeekday = baseDate.clone().add(6, "days");

      this.loading = true;
      this.loadingError = false;
      userApi
        .getUser(this.currentUser._id)
        .then(res => {
          const user = res.data.data;
          this.loading = false;
          this.loadingError = false;
          if (user.companionStudentSettings.slotDuration) {
            this.slotLength = parseInt(
              user.companionStudentSettings.slotDuration,
              10
            );
          }
        })
        .catch(err => {
          this.loading = false;
          this.loadingError = true;
        });

      this.loadCalendar();
    }
  }
};
</script>
